import React, { useState, FC } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import styled from 'styled-components'
import { space } from 'styled-system'
import { COPY_TIMEOUT } from '../../../utils/variables'
import { Button } from '../../button'
import { Container } from '../../container'
import { CheckmarkIcon } from '../../icons/checkmark'
import { CopyIcon } from '../../icons/copy'
import { HiddenLabel } from '../../typography'
import { InputLabel } from '../text-field/labels'
import { StyledTextAreaProps, TextAreaProps } from './types'

export const TextArea: FC<TextAreaProps> = ({
  label,
  hiddenLabel,
  value,
  copyToClipboard,
  rows = 4,
  ...spacing
}) => {
  const [isCopied, setIsCopied] = useState(false)

  const handleOnCopy = () => {
    setIsCopied(true)
    setTimeout(() => setIsCopied(false), COPY_TIMEOUT)
  }

  const renderPostFix = (isCopyToClipboard: boolean) => {
    if (isCopyToClipboard) {
      return (
        <PostFixContainer>
          <CopyToClipboard text={value || ''} onCopy={handleOnCopy}>
            <Button
              variant="fainted"
              plain
              icon={isCopied ? <CheckmarkIcon /> : <CopyIcon />}
              aria-label={isCopied ? 'Copied' : 'Copy'}
            />
          </CopyToClipboard>
        </PostFixContainer>
      )
    }
    return null
  }

  return (
    <Container display="flex" flexDirection="column" {...spacing}>
      {label && <InputLabel labelText={label} />}
      <Container position="relative">
        {hiddenLabel && <HiddenLabel>{hiddenLabel}</HiddenLabel>}
        <StyledTextArea value={value} copyToClipboard={copyToClipboard} rows={rows} />
        {renderPostFix(Boolean(copyToClipboard))}
      </Container>
    </Container>
  )
}

const PostFixContainer = styled.span`
  position: absolute;
  top: 1.2rem;
  right: 1.2rem;
  display: flex;
  align-items: center;
`

export const StyledTextArea = styled('textarea')<StyledTextAreaProps>`
  font-family: ${({ theme }) => theme.fonts.default};
  font-size: 1.5rem; // textRegular
  line-height: 1.467;
  letter-spacing: -0.017em;
  color: ${({ theme }) => theme.colors.black};
  border: 1px solid ${({ theme }) => theme.colors.grey};
  padding: 1.2rem 1.6rem;
  padding-right: ${({ copyToClipboard }) => (copyToClipboard ? '5.2rem' : '1.6rem')};
  border-radius: 0.7rem;
  width: 100%;
  outline: 0;
  font-weight: 400;
  overflow: hidden;
  resize: none;
  ${space};
`
