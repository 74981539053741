import styled from 'styled-components'
import { ListItemsProps } from '.'
import { Container } from '../container'
import { Caption } from '../typography'

export const ListContainer = styled(Container)`
  padding-bottom: 1.2rem;
  margin-top: 1.2rem;
  color: ${({ theme }) => theme.colors.black};
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.greyLight};
  &:last-child {
    border-bottom: 0;
  }
`

export const DisclosureButtonContainer = styled(Container)`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 1.6rem;
`

export const DisclosureButtonWrapper = styled(Container)`
  width: 100%;
  cursor: pointer;
`

export const ListHeaderText = styled(Caption)`
  margin-left: 0.8rem;
  color: ${({ theme }) => theme.colors.greySuperDark};
  white-space: nowrap;
`

export const ArrowBtn = styled.span<ListItemsProps>`
  display: flex;
  transform: rotate(${({ open }) => (open ? '0' : '180deg')});
`

export const ListItemsContainer = styled(Container)<ListItemsProps>`
  margin-top: ${({ open }) => (open ? '0.8rem' : '0rem')};
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
`
