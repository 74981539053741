import React from 'react'

export const CloseBoldIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4 3a1 1 0 0 0-.707.293 1 1 0 0 0 0 1.414L6.586 8l-3.293 3.293a1 1 0 0 0 0 1.414 1 1 0 0 0 1.414 0L8 9.414l3.293 3.293a1 1 0 0 0 1.414 0 1 1 0 0 0 0-1.414L9.414 8l3.293-3.293a1 1 0 0 0 0-1.414A1 1 0 0 0 12 3a1 1 0 0 0-.707.293L8 6.586 4.707 3.293A1 1 0 0 0 4 3z" />
    </svg>
  )
}
