import React, { FC, useMemo, useRef, useState, useCallback, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { hasColorMatchPercentage } from '../../utils/has-color-match-percentage'
import { hasTransparentPixels } from '../../utils/has-transparent-pixels'
import { Container } from '../container'
import { StatusBadge } from '../status-badge'
import { Text } from '../typography'
import { DeletedAvatar } from './deleted'
import {
  AvatarStatusBadgeProps,
  AvatarImageProps,
  AvatarVariant,
  GeneralAvatarProps,
} from './types'

const DEFAULT_SIZE = '3.6rem'
const DEFAULT_FONT_SIZE = '1.6rem'
const DEFAULT_BORDER_RADIUS = '0.8rem'
const WHITE_COLOR_RGB: [number, number, number] = [255, 255, 255]

function parseAvatarStyles(variant?: AvatarVariant, borderRadius?: string) {
  switch (variant) {
    case AvatarVariant.SQUARE:
      return css`
        border-radius: ${borderRadius || DEFAULT_BORDER_RADIUS};
      `
    case AvatarVariant.CIRCLE:
    default:
      return css`
        border-radius: 50%;
      `
  }
}

export const Avatar: FC<GeneralAvatarProps> = ({
  variant = AvatarVariant.SQUARE,
  label,
  name,
  bgColor,
  imgUrl,
  size = DEFAULT_SIZE,
  fontSize = DEFAULT_FONT_SIZE,
  iconSize,
  flag,
  status,
  borderRadius,
  isDeleted,
  firstName,
  lastName,
  icon,
  isAvatarExists = false,
  onClick,
  hasBorder = true,
  ...stylesProps
}) => {
  const imgRef = useRef<HTMLImageElement>(null)
  const [hasWhiteBg, setHasWhiteBg] = useState(false)
  const [imgError, setImgError] = useState(false)

  const isImgDisplayed = useMemo(() => imgUrl && !imgError, [imgUrl, imgError])

  const bgColorComputed = useMemo(() => {
    switch (true) {
      case isDeleted:
        return 'grey'
      case !isAvatarExists:
        return bgColor
      case hasWhiteBg:
        return 'white'
      default:
        return 'greyLight'
    }
  }, [isDeleted, hasWhiteBg, isAvatarExists, bgColor])

  const handleImageLoad = useCallback(() => {
    if (imgRef.current && parseFloat(size) >= 3.6 && hasBorder) {
      const whiteBg =
        hasTransparentPixels(imgRef.current) ||
        hasColorMatchPercentage(imgRef.current, WHITE_COLOR_RGB, 30, 10)
      setHasWhiteBg(whiteBg)
    }
  }, [hasBorder, size])

  useEffect(() => {
    setImgError(false)
    setHasWhiteBg(false)
  }, [imgUrl])

  return (
    <Container
      width={size}
      height={size}
      position="relative"
      onClick={onClick}
      as={onClick ? 'button' : 'div'}
      display="block"
      aria-label={label || `${firstName || ''} ${lastName || ''}`}
      flex="0 0 auto"
      {...stylesProps}
    >
      <AvatarImageContainer
        backgroundColor={bgColorComputed}
        variant={variant}
        borderRadius={borderRadius}
        hasWhiteBg={hasWhiteBg}
        as="span"
      >
        {isDeleted ? (
          <DeletedAvatar iconSize={iconSize} flag={flag} />
        ) : isImgDisplayed ? (
          <img
            ref={imgRef}
            src={imgUrl}
            alt={name || `${firstName || ''} ${lastName || ''}`}
            crossOrigin="anonymous"
            onLoad={handleImageLoad}
            onError={() => setImgError(true)}
          />
        ) : (
          !isAvatarExists &&
          (label || firstName) && (
            <Label as="span" fontSize={fontSize}>
              {label?.charAt(0) ?? firstName?.charAt(0)}
            </Label>
          )
        )}
        {icon}
      </AvatarImageContainer>
      {status && !isDeleted && (
        <StatusBadgeContainer>
          <StatusBadge status={status} />
        </StatusBadgeContainer>
      )}
    </Container>
  )
}

const AvatarImageContainer = styled(Container)<AvatarImageProps & { hasWhiteBg?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 100%;

  color: ${({
    theme: {
      colors: { white },
    },
  }) => white};

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  border: ${({ hasWhiteBg, theme }) =>
    hasWhiteBg ? `1px solid ${theme.colors.background}` : 'none'};
  ${({ variant, borderRadius }) => parseAvatarStyles(variant, borderRadius)};
`

const StatusBadgeContainer = styled.span<AvatarStatusBadgeProps>`
  position: absolute;
  bottom: -0.25rem;
  right: -0.25rem;
  height: 1.2rem;
  width: 1.2rem;
`

const Label = styled(Text)`
  text-transform: uppercase;
`
