import React, { FC } from 'react'
import { ToastContainer as ReactToastContainer } from 'react-toastify'
import styled, { useTheme } from 'styled-components'
import { CloseBoldIcon, ErrorIcon, SuccessIcon } from 'ui'

export const ToastContainer: FC = () => {
  const theme = useTheme()

  const getToastIcon = (type: string) => {
    switch (type) {
      case 'error':
        return <ErrorIcon fill={theme.colors.red} width={32} height={32} />
      case 'success':
        return <SuccessIcon fill={theme.colors.green} width={32} height={32} />
      default:
        return null
    }
  }

  return (
    <StyledToastContainer
      hideProgressBar
      icon={({ type }) => getToastIcon(type)}
      closeButton={<CloseBoldIcon fill={theme.colors.black} />}
    />
  )
}

export const StyledToastContainer = styled(ReactToastContainer)`
  &&&.Toastify__toast-container {
    padding-top: 1.4rem;
    padding-right: 1.2rem;
  }
  .Toastify__toast {
    border-radius: 0.8rem;
    border: 1px solid transparent;
    padding: 0.5rem 0.8rem;
    min-height: 4.4rem;
    margin-left: auto;
    box-shadow: none;
    align-items: center;
    &--success {
      background-color: ${({ theme }) => theme.colors.greenLighter};
      border-color: ${({ theme }) => theme.colors.greenLight};
    }
    &--error {
      background-color: ${({ theme }) => theme.colors.redLighter};
      border-color: ${({ theme }) => theme.colors.redLight};
    }
  }
  .Toastify__toast-body {
    margin: 0;
    padding: 0 0.4rem;
    font-size: 1.5rem;
    line-height: 1.2;
    color: ${({ theme }) => theme.colors.black};
  }
  .Toastify__toast-icon {
    width: 3.2rem;
  }
  .Toastify__close-button {
    align-self: center;
    width: 1.6rem;
    height: 1.6rem;
    &--light {
      color: ${({ theme }) => theme.colors.black};
      opacity: 1;
    }
  }
`
