import styled, { DefaultTheme } from 'styled-components'
import { SpaceProps, space } from 'styled-system'
import { ListItemButtonProps } from '.'
import { Text } from '../typography'

export const ListItemText = styled(Text)`
  color: ${({ theme }) => theme.colors.black};
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 19.6rem;
  overflow: hidden;
  padding-left: 0.8rem;
`

// TODO: Change svg color to fill after editing icons
const commonListItemStyles = (theme: DefaultTheme) => `
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 3.2rem;
  padding: 0.4rem 1.6rem;
  transition: background-color ${theme.transitionTimes.short};
  &:hover {
    background-color: ${theme.colors.greyLighter};
  }
  & svg {
    color: ${theme.colors.greyDark};
  }

`

export const ListItemButton = styled.button<ListItemButtonProps>`
  ${({ theme }) => commonListItemStyles(theme)}
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.greyLight : 'transparent'};
  ${space}
`

export const ListItemLink = styled.a<SpaceProps>`
  ${({ theme }) => commonListItemStyles(theme)}
  ${space}
`

export const IconContainer = styled.span`
  width: 2.4rem;
  height: 2.4rem;
  flex: 0 0 auto;
`
