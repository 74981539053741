import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'
import { Container, generalSettings } from '..'
import { GeneralScrollContainerProps, ScrollContainerProps } from './types'

//TODO: add prop for fading effect
export const ScrollContainer = forwardRef<HTMLDivElement, GeneralScrollContainerProps>(
  ({ children, ...otherStyles }, ref) => {
    // tabIndex is necessary for accessibility https://dequeuniversity.com/rules/axe/4.4/scrollable-region-focusable?application=axeAPI
    return (
      <StyledScrollContainer ref={ref} tabIndex={0} {...otherStyles}>
        <>{children}</>
      </StyledScrollContainer>
    )
  }
)

ScrollContainer.displayName = 'ScrollContainer'

const StyledScrollContainer = styled(Container).withConfig(
  generalSettings
)<GeneralScrollContainerProps>`
  overflow-y: auto;
  height: ${(props) => (props.height ? props.height : '100%')};
  ${(props) =>
    !props.hideScrollbar &&
    css`
      padding-right: 1rem;
      margin-right: -1.5rem;
    `}
  scrollbar-color: ${({ theme }) => theme.colors.grey} ${({ theme }) => theme.colors.transparent};
  scrollbar-width: ${(props) => (props.hideScrollbar ? 'none' : 'thin')};

  &::-webkit-scrollbar {
    width: 1.3rem;

    ${(props) =>
      props.hideScrollbar &&
      css`
        display: none;
      `}
  }

  &::-webkit-scrollbar-thumb {
    border: 0.4rem solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 2rem;
    background-color: ${({ theme }) => theme.colors.grey};
  }
`
