import React, { FC } from 'react'
import { Container, Text } from 'ui'
import { CaptionsContainer } from '../../../shared/ui/components/captions-container'

interface AudienceErrorProps {
  errorMessage: string
}

export const AudienceError: FC<AudienceErrorProps> = ({ errorMessage }) => {
  return (
    <CaptionsContainer>
      <Container display="flex" flex={1} justifyContent="center" alignItems="center">
        <Text variant="textRegular">Error: {JSON.stringify(errorMessage)}</Text>
      </Container>
    </CaptionsContainer>
  )
}
