import { defaultImport } from 'default-import'
import React, { useState, FC } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import defaultStyled from 'styled-components'
import { COPY_TIMEOUT } from '../../../utils/variables'
import { Button } from '../../button'
import { Container } from '../../container'
import { EyeMuteIcon } from '../../icons'
import { EyeIcon } from '../../icons'
import { CheckmarkIcon } from '../../icons/checkmark'
import { CopyIcon } from '../../icons/copy'
import { Caption, HiddenLabel } from '../../typography'
import { GenericInput } from './generic-input'
import { InputLabel } from './labels'
import { TextFieldProps } from './types'

const styled = defaultImport(defaultStyled)

export const TextField: FC<TextFieldProps> = ({
  id,
  name,
  placeholder,
  label,
  hiddenLabel,
  maxLength,
  helperText,
  warning,
  postFix,
  disabled,
  type = 'text',
  value,
  iconHiddenLabel,
  toastMessage,
  copyToClipboard,
  toggleVisibility,
  autoFocus,
  onChange,
  onFocus,
  onBlur,
  onKeyDown,
  variant,
  sizeVariant,
  description,
  tooltipText,
  ...spacing
}) => {
  const [isValueVisible, setIsValueVisible] = useState<boolean>(false)
  const [isCopied, setIsCopied] = useState(false)

  const handleOnCopy = () => {
    setIsCopied(true)
    setTimeout(() => setIsCopied(false), COPY_TIMEOUT)
  }

  const renderPostFix = (
    isCopyToClipboard: boolean,
    toggleVisibility: boolean,
    hasPostFix: boolean
  ) => {
    switch (true) {
      case isCopyToClipboard:
        return (
          <PostFixContainer>
            <CopyToClipboard text={value || ''} onCopy={handleOnCopy}>
              <Button
                variant="fainted"
                plain
                icon={isCopied ? <CheckmarkIcon /> : <CopyIcon />}
                aria-label={isCopied ? 'Copied' : 'Copy'}
              />
            </CopyToClipboard>
          </PostFixContainer>
        )
      case toggleVisibility:
        return (
          <PostFixContainer>
            <Button
              variant="fainted"
              plain
              icon={isValueVisible ? <EyeMuteIcon /> : <EyeIcon />}
              aria-label={isValueVisible ? 'Visible' : 'Hidden'}
              onClick={() => setIsValueVisible(!isValueVisible)}
            />
          </PostFixContainer>
        )
      case hasPostFix:
        return <PostFixContainer>{postFix}</PostFixContainer>
      default:
        return null
    }
  }

  const getInputType = (type: React.HTMLInputTypeAttribute) => {
    if (toggleVisibility && !isValueVisible) {
      return 'password'
    }
    return type
  }

  return (
    <Container display="flex" flexDirection="column" {...spacing}>
      {label && <InputLabel htmlFor={name} labelText={label} tooltipText={tooltipText} />}
      <Container position="relative">
        {hiddenLabel && <HiddenLabel>{hiddenLabel}</HiddenLabel>}
        <GenericInput
          id={id}
          name={name}
          contentEditable
          warning={warning}
          disabled={disabled}
          helperText={description || helperText}
          placeholder={placeholder}
          value={value}
          maxLength={maxLength}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          type={getInputType(type)}
          sizeVariant={sizeVariant}
          variant={variant}
          postFix={postFix}
          autoComplete="off"
          autoFocus={autoFocus}
        />
        {Boolean(helperText || description) && (
          <Container position="absolute" left="1.2rem" bottom="0.4rem">
            <HelperText isDescription={Boolean(description)}>
              {helperText ?? description}
            </HelperText>
          </Container>
        )}
        {renderPostFix(Boolean(copyToClipboard), Boolean(toggleVisibility), Boolean(postFix))}
      </Container>
    </Container>
  )
}

export const HelperText = styled(Caption)<{ isDescription?: boolean }>`
  font-family: ${({ theme }) => theme.fonts.default};
  font-size: 1.2rem;
  color: ${({ theme, isDescription }) =>
    isDescription ? theme.colors.greyDark : theme.colors.red};
`

const PostFixContainer = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1.2rem;
  display: flex;
  align-items: center;
`
