import { defaultImport } from 'default-import'
import React from 'react'
import defaultStyled, { css } from 'styled-components'
import { space } from 'styled-system'
import { HiddenLabel, Text } from '../typography'
import { InputWrapperProps, ToggleButtonProps } from './types'

const styled = defaultImport(defaultStyled)

export const ToggleButton: React.FC<ToggleButtonProps> = ({
  id,
  label,
  desc,
  disabled,
  checked,
  unlabeled,
  onClick,
  ...spacing
}) => (
  <InputWrapper as="label" htmlFor={id} unlabeled={unlabeled} disabled={disabled} {...spacing}>
    <InputLabel>
      {unlabeled ? <HiddenLabel>{label}</HiddenLabel> : <Text>{label}</Text>}
      {desc && <Description>{desc}</Description>}
    </InputLabel>
    <Input
      type="checkbox"
      role="switch"
      onClick={onClick}
      onChange={() => null}
      disabled={disabled}
      checked={checked}
      id={id}
      aria-checked={checked}
    />
    <Slider />
  </InputWrapper>
)

const InputWrapper = styled(Text)<InputWrapperProps>`
  display: inline-block;
  font-size: 1.8rem;
  line-height: 1.3;
  ${(props) =>
    !props.unlabeled &&
    css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.5rem;
      cursor: ${props.disabled ? 'default' : 'pointer'};
      user-select: none;
    `}
  position: relative;
  ${space}
`

const InputLabel = styled.span`
  display: flex;
  flex-direction: column;
`

const Description = styled.span`
  color: ${({ theme }) => theme.colors.greyDark};
  font-size: 1.4rem;
  line-height: 1.4;
  padding: 0.2rem 4.5rem 0 0;
`

const Input = styled.input`
  position: absolute;
  cursor: pointer;
  left: -100rem;
  top: -100rem;
  &:checked + span {
    background-color: ${({ theme }) => theme.colors.primary};
    &::before {
      left: 2.2rem;
    }
  }
  &:disabled + span {
    cursor: default;
    background-color: ${({ theme }) => theme.colors.greyLight};
    &::before {
      background-color: ${({ theme }) => theme.colors.greyDark};
    }
  }
  &:disabled:checked + span {
    background-color: ${({ theme }) => theme.colors.grey};
    &::before {
      background-color: ${({ theme }) => theme.colors.greyLight};
    }
  }
  &:focus + span,
  &:hover + span {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.1);
  }
  &:focus:checked + span,
  &:hover:checked + span {
    box-shadow: 0 0 0 0.2rem rgba(24, 144, 255, 0.2);
  }
  &:disabled:focus + span,
  &:disabled:hover + span {
    box-shadow: none;
  }
`

const Slider = styled.span`
  ${({ theme }) => ({
    backgroundColor: theme.colors.greyDark,
    transition: `background-color ${theme.transitionTimes.short}, box-shadow ${theme.transitionTimes.short}`,
  })}
  position: relative;
  display: inline-flex;
  align-self: flex-start;
  flex: 0 0 5.2rem;
  cursor: pointer;
  height: 3.2rem;
  border-radius: 10rem;
  width: 5.2rem;
  &::before {
    ${({ theme }) => ({
      transitionDuration: theme.transitionTimes.short,
      backgroundColor: theme.colors.white,
    })}
    content: '';
    position: absolute;
    top: 0.2rem;
    left: 0.2rem;
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 4.5rem;
    box-shadow: 0 0.3rem 0.8rem rgba(0, 0, 0, 0.15), 0 0.1rem 0.1rem rgba(0, 0, 0, 0.16),
      0 0.3rem 0.1rem rgba(0, 0, 0, 0.1);
  }
`
