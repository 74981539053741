import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'
import { space, SpaceProps } from 'styled-system'
import { getStatusIconStyles } from '../../../src/utils/get-status-icon-styles'
import { AvatarStatusBadgeProps, StatusVariant } from '../avatar/types'
import { StatusIcon, StatusLgIcon } from '../icons'
import { HiddenLabel } from '../typography'

export interface StatusBadgeProps extends SpaceProps {
  status: StatusVariant
  sizeLg?: boolean
}

export const DEFAULT_STATUS: StatusVariant = StatusVariant.OFFLINE

export const StatusBadge: FC<StatusBadgeProps> = ({ status, sizeLg, ...spacing }) => {
  const currentStatus = status || DEFAULT_STATUS
  const theme = useTheme()
  const iconStyles = getStatusIconStyles(currentStatus, theme)

  const { t } = useTranslation('common')

  return (
    <StyledStatusIcon status={currentStatus} {...spacing}>
      <HiddenLabel>{t('userStatus', { status: currentStatus })}</HiddenLabel>
      {sizeLg ? (
        <StatusLgIcon width={24} height={24} {...iconStyles} preserveAspectRatio="none" />
      ) : (
        <StatusIcon width={12} height={12} {...iconStyles} />
      )}
    </StyledStatusIcon>
  )
}

const StyledStatusIcon = styled.span<AvatarStatusBadgeProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${space}
`
