import { defaultImport } from 'default-import'
import React, { forwardRef } from 'react'
import defaultStyled from 'styled-components'
import { GeneralButton, Inner, Icon, StyledText } from '../button'
import { ButtonStylesProps } from '../button/types'
import { HiddenLabel } from '../typography'
import { ButtonLinkInterface } from './types'

const styled = defaultImport(defaultStyled)

export const ButtonLink = forwardRef<HTMLAnchorElement, ButtonLinkInterface>((props, ref) => {
  const {
    id,
    children,
    preFix,
    postFix,
    icon,
    variant,
    size,
    circle,
    plain,
    disabled,
    hiddenLabel,
    href,
    newTab,
    onClick,
    ...spacing
  } = props

  return (
    <GeneralButtonLink
      id={id}
      ref={ref}
      variant={variant}
      size={size}
      circle={circle}
      plain={plain}
      disabled={disabled}
      href={href}
      onClick={onClick}
      {...(newTab && { target: '_blank' })}
      {...spacing}
    >
      <Inner plain={plain}>
        {preFix && <Icon mr="0.8rem">{preFix}</Icon>}
        {icon && <Icon>{icon}</Icon>}
        {children && !icon && (
          <StyledText as="span" variant={size === 'sm' ? 'smallTextMedium' : 'textMedium'}>
            {children}
          </StyledText>
        )}
        {postFix && <Icon ml="0.8rem">{postFix}</Icon>}
        {hiddenLabel && <HiddenLabel>{hiddenLabel}</HiddenLabel>}
      </Inner>
    </GeneralButtonLink>
  )
})

ButtonLink.displayName = 'ButtonLink'

const GeneralButtonLink = styled(GeneralButton.withComponent('a'))<ButtonStylesProps>``
