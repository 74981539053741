import React, { FC } from 'react'
import styled, { css } from 'styled-components'
import { Text } from '../../typography'
import { PopperActionStylesProps, PopperActionVariant, PopperButtonProps } from '../types'

export const PopperButton: FC<PopperButtonProps> = ({ variant, icon, name, onClick }) => {
  return (
    <PopperItemButton type="button" variant={variant} onClick={onClick}>
      {icon && <Icon>{icon}</Icon>}
      <Text
        as="span"
        color={variant === PopperActionVariant.WARNING ? 'red' : 'black'}
        display="block"
        textAlign="left"
      >
        {name}
      </Text>
    </PopperItemButton>
  )
}

const PopperItemButton = styled.button<PopperActionStylesProps>`
  ${({ variant, theme }) => {
    return css`
      color: ${variant === PopperActionVariant.WARNING ? theme.colors.red : theme.colors.black};
    `
  }};
  padding: 0.8rem 1.2rem;
  height: 4rem;
  width: 100%;
  display: flex;
  align-items: center;
  transition: background-color ${({ theme }) => theme.transitionTimes.short};
  cursor: pointer;
  &:hover {
    border: none;
    background-color: ${({ theme }) => theme.colors.greyLight};
  }
`

const Icon = styled.span`
  margin-right: 0.8rem;
  width: 2.4rem;
  height: 2.4rem;
`
