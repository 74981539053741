import React from 'react'

export const UserDeletedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15 9a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm2 0A5 5 0 1 1 7 9a5 5 0 0 1 10 0ZM4 16a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2H4Zm11.293-1.707a1 1 0 0 1 1.414 0L18 15.586l1.293-1.293a1 1 0 0 1 1.414 1.414L19.414 17l1.293 1.293a1 1 0 0 1-1.414 1.414L18 18.414l-1.293 1.293a1 1 0 0 1-1.414-1.414L16.586 17l-1.293-1.293a1 1 0 0 1 0-1.414Z"
        fillRule="evenodd"
      />
    </svg>
  )
}
