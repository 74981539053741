import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '0.8rem 1.2rem',
    backgroundColor: `${theme.colors.black}`,
    color: `${theme.colors.white}`,
    maxWidth: `27.8rem`,
    fontSize: `1.3rem`,
    borderRadius: '0.6rem',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: `${theme.colors.black}`,
  },
}))
