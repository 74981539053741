import React, { SVGProps } from 'react'

export const HelpIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12 3c-4.959 0-9 4.041-9 9s4.041 9 9 9 9-4.041 9-9-4.041-9-9-9zm0 2c3.878 0 7 3.122 7 7s-3.122 7-7 7-7-3.122-7-7 3.122-7 7-7Z" />
      <path d="M12.51 7.045a3.4 3.4 0 0 0-3.781 2.223 1 1 0 0 0 .61 1.275 1 1 0 0 0 1.276-.611 1.399 1.399 0 0 1 1.557-.916 1.403 1.403 0 0 1 1.164 1.382 1 1 0 0 0 0 .002c0 .3-.282.653-.754.967-.472.315-.963.485-.963.485a1 1 0 0 0-.633 1.263 1 1 0 0 0 1.266.633s.711-.231 1.44-.717c.727-.485 1.644-1.33 1.644-2.63a3.398 3.398 0 0 0-2.826-3.356Z" />
      <circle cx="12" cy="16" r="1" />
    </svg>
  )
}
