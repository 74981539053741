import React from 'react'

export const ArchiveIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4 3C2.355 3 1 4.355 1 6v1c0 1.645 1.355 3 3 3h16c1.645 0 3-1.355 3-3V6c0-1.645-1.355-3-3-3Zm0 2h16c.564 0 1 .436 1 1v1c0 .564-.436 1-1 1H4c-.564 0-1-.436-1-1V6c0-.564.436-1 1-1Z" />
      <path d="M4.059 8.059a1 1 0 0 0-1 1v9a3.001 3.001 0 0 0 3 3h12a3.001 3.001 0 0 0 3-3v-9a1 1 0 0 0-1-1 1 1 0 0 0-1 1v9a.999.999 0 0 1-1 1h-12a.999.999 0 0 1-1-1v-9a1 1 0 0 0-1-1zm6 4a1 1 0 0 0-1 1 1 1 0 0 0 1 1h4a1 1 0 0 0 1-1 1 1 0 0 0-1-1z" />
    </svg>
  )
}
