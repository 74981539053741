import React, { FC, SVGProps } from 'react'

export const AddUserIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 10C13 11.6569 11.6569 13 10 13C8.34315 13 7 11.6569 7 10C7 8.34315 8.34315 7 10 7C11.6569 7 13 8.34315 13 10ZM15 10C15 12.7614 12.7614 15 10 15C7.23858 15 5 12.7614 5 10C5 7.23858 7.23858 5 10 5C12.7614 5 15 7.23858 15 10ZM3 17C2.44772 17 2 17.4477 2 18C2 18.5523 2.44772 19 3 19L17 19C17.5523 19 18 18.5523 18 18C18 17.4477 17.5523 17 17 17L3 17ZM20 5C20.5523 5 21 5.44772 21 6V8H23C23.5523 8 24 8.44772 24 9C24 9.55228 23.5523 10 23 10H21V12C21 12.5523 20.5523 13 20 13C19.4477 13 19 12.5523 19 12V10H17C16.4477 10 16 9.55228 16 9C16 8.44772 16.4477 8 17 8H19V6C19 5.44772 19.4477 5 20 5Z"
    />
  </svg>
)
