import React, { FC, PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'
import { space } from 'styled-system'
import { Container } from '../container'
import { SortingButtonProps } from './types'

export const SortingButton: FC<SortingButtonProps> = ({
  onClick,
  icon,
  children,
  ...spaceProps
}) => {
  return (
    <SortingButtonContainer type="button" onClick={onClick} {...spaceProps}>
      {children}
      {icon && <SortingIconWrapper>{icon}</SortingIconWrapper>}
    </SortingButtonContainer>
  )
}

export const FakeSortingButton: FC<PropsWithChildren> = ({ children }) => {
  return <SortingFakeButton>{children}</SortingFakeButton>
}

const sharedStyles = css`
  display: flex;
  align-items: center;
  padding: 0.3rem 0;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 1.385;
  letter-spacing: -0.008em;
  font-family: ${({ theme }) => theme.fonts.default};
  color: ${({ theme }) => theme.colors.greyDark};
`

const SortingFakeButton = styled(Container)`
  ${sharedStyles};
  cursor: default;
`

const SortingButtonContainer = styled.button`
  ${sharedStyles};
  transition: ${({ theme }) => theme.transitionTimes.short};
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
  ${space}
`
const SortingIconWrapper = styled.span`
  height: 1.6rem;
  width: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.4rem;
  & svg {
    height: 1.6rem;
    width: 1.6rem;
  }
`
