import { defaultImport } from 'default-import'
import React from 'react'
import defaultStyled, { css, useTheme } from 'styled-components'
import { space } from 'styled-system'
import { Container, generalSettings } from '../container'
import { Text } from '../typography'
import { RadioButtonProps, RadioButtonStylesProps } from './types'

const styled = defaultImport(defaultStyled)

export const RadioButton: React.FC<RadioButtonProps> = ({
  label,
  name,
  value,
  disabled,
  checked,
  onChange,
  reverse,
  desc,
  border,
  preFix,
  ...spacing
}) => {
  const theme = useTheme()
  return (
    <InputRow {...spacing}>
      <InputWrapper htmlFor={value} reverse={reverse} border={border}>
        {!reverse && preFix}
        <Input
          type="radio"
          id={value}
          name={name}
          value={value}
          disabled={disabled}
          checked={checked}
          aria-checked={checked}
          onChange={onChange}
        />
        <Label as="span" reverse={reverse} disabled={disabled} desc={desc}>
          {label}
          <Text
            variant="smallTextRegular"
            as="span"
            display="block"
            color={theme.colors.greySuperDark}
          >
            {desc}
          </Text>
        </Label>
        {reverse && preFix}
      </InputWrapper>
    </InputRow>
  )
}

const InputRow = styled(Container).withConfig(generalSettings)`
  width: 100%;
  ${space}
`

const InputWrapper = styled.label<RadioButtonStylesProps>`
  ${(props) => {
    const borderStyles = css`
      padding: 1rem;
      border: 1px solid ${({ theme }) => theme.colors.grey};
      border-radius: 0.7rem;
    `

    return css`
      position: relative;
      display: inline-flex;
      flex-direction: ${props.reverse ? 'row-reverse' : 'auto'};
      align-items: center;
      width: 100%;
      ${props.border && borderStyles}
    `
  }}
`

const Label = styled(Text)<RadioButtonStylesProps>`
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  padding: ${(props) => (props.reverse ? '0 1.2rem 0 0' : '0 0 0 1.2rem')};
  align-self: center;
  flex: ${(props) => (props.reverse ? '1 1 auto' : '0 0 auto')};
  transition: color ${({ theme }) => theme.transitionTimes.short};
  color: ${(props) => (props.disabled ? props.theme.colors.greyDark : props.theme.colors.black)};
  font-weight: ${(props) => (props.desc ? 500 : 400)};
`

const Input = styled('input').withConfig(generalSettings)<RadioButtonStylesProps>`
  /* Base styles */
  appearance: none;
  outline: none;
  border-radius: 50%;
  cursor: pointer;
  flex: 0 0 auto;
  display: grid;
  place-content: center;
  margin-top: -0.1rem;
  width: 2.4rem;
  height: 2.4rem;
  background: ${({ theme }) => theme.colors.transparent};
  border: 0.1rem solid ${({ theme }) => theme.colors.greyDark};
  transition: border-color ${({ theme }) => theme.transitionTimes.short};
  &::before {
    width: 1.6rem;
    height: 1.6rem;
    content: '';
    transition: background ${({ theme }) => theme.transitionTimes.short};
    background: transparent;
    border-radius: 50%;
  }
  &:hover {
    border-color: ${({ theme }) => theme.colors.primaryDarker};
  }
  &:checked {
    border-color: ${({ theme }) => theme.colors.primary};
    &::before {
      background: ${({ theme }) => theme.colors.primary};
    }
    &:hover::before {
      background: ${({ theme }) => theme.colors.primaryDarker};
    }
  }
  &:disabled {
    cursor: initial;
    border-color: ${({ theme }) => theme.colors.grey};
    &:checked::before {
      background: ${({ theme }) => theme.colors.grey};
    }
    &:checked:hover::before {
      background: ${({ theme }) => theme.colors.grey};
    }
    ${Label} {
      color: ${({ theme }) => theme.colors.greyDark};
    }
  }
`
