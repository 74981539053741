import styled, { css, DefaultTheme } from 'styled-components'
import { space } from 'styled-system'
import { Text } from '../typography'
import { TabButtonProps, TabListProps, TabsVariant } from './types'

const getVariantStyles = (theme: DefaultTheme, variant?: TabsVariant, isSelected?: boolean) => {
  const defaultHoverStyles = css`
    &:hover {
      color: ${theme.colors.primaryDarker};
    }
  `
  const underlineStyles = css`
    &:hover {
      color: ${theme.colors.primaryDarker};
      &::after {
        background: ${theme.colors.primaryDarker};
      }
    }
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 0.3rem;
      border-radius: 0.2rem;
      transition: background ${theme.transitionTimes.short};
      background: ${theme.colors.primary};
    }
  `

  switch (variant) {
    case TabsVariant.UNDERLINE:
      return {
        color: isSelected ? theme.colors.primary : theme.colors.greyDark,
        underline: isSelected && underlineStyles,
        hover: defaultHoverStyles,
      }
    default:
      return {
        color: isSelected ? theme.colors.primary : theme.colors.greyDark,
        borderRadius: '0',
        hover: defaultHoverStyles,
      }
  }
}

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  ${space}
`

export const TabList = styled.ul<TabListProps>`
  display: flex;
  align-items: center;
  min-height: 3.2rem;
  margin-bottom: ${(props) => props.listMarginBottom || '2rem'};

  & > li:not(:last-of-type) {
    margin-right: 2.4rem;
  }
`

export const TabButton = styled.button<TabButtonProps>`
  ${(props) => {
    const { color, underline, hover } = getVariantStyles(
      props.theme,
      props.variant,
      props.isSelected
    )

    return css`
      display: block;
      position: relative;
      padding: 0.5rem 0;
      color: ${color};
      transition: color ${props.theme.transitionTimes.short};
      ${underline}
      ${hover}
    `
  }}
  ${Text} {
    color: inherit;
  }
`
