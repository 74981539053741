export const hasColorMatchPercentage = (
  img: HTMLImageElement,
  targetColor: [number, number, number],
  requiredPercentage: number,
  tolerance: number = 0
): boolean => {
  try {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    if (!ctx) return false

    canvas.width = img.width
    canvas.height = img.height
    ctx.drawImage(img, 0, 0, img.width, img.height)

    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
    const { data } = imageData

    const [targetR, targetG, targetB] = targetColor
    let matchingPixels = 0
    const totalPixels = img.width * img.height

    for (let i = 0; i < data.length; i += 4) {
      const [r, g, b] = data.slice(i, i + 3)

      // Check if the pixel matches the target color within a tolerance
      const isMatch =
        Math.abs(r - targetR) <= tolerance &&
        Math.abs(g - targetG) <= tolerance &&
        Math.abs(b - targetB) <= tolerance

      if (isMatch) matchingPixels++

      // Early exit: if enough pixels match to exceed the required percentage
      if ((matchingPixels / totalPixels) * 100 >= requiredPercentage) {
        return true
      }
    }

    return false
  } catch (error) {
    console.error('Error in checking color match percentage:', error)
    return false
  }
}
