import { DefaultTheme } from 'styled-components'
import { breakpoints } from './media-queries'

const commonColors = {
  white: '#FFF',
  black: '#373234',
  primary: '#003BA4',
  primaryDarker: '#003683',
  primaryDark: '#002861',
  primaryLight: '#99B1DB',
  primaryLighter: '#D6E0F0',
  primaryLoading: '#4875C3',
  red: '#D24821',
  redDark: '#A03050',
  redLight: '#F8DBD3',
  redLighter: '#FCEDE9',
  greenLight: '#CCFFE9',
  greenLighter: '#F0FFF9',
  green: '#007443',
  yellow: '#F6B439',
  yellowLighter: '#FFF9EE',
  background: '#F2EDE7',
  grey: '#D1CBC4',
  greyLighter: '#FCFAF6',
  greyLight: '#F6F3EE',
  greyDark: '#A9A49C',
  greySuperDark: '#6F695F',
  pinkLight: '#FFF7EF',
  beigeLight: '#F0E9E1',
  beige: '#E9E2DA',
  beigeDark: '#E8E1D9',
  smoke: '#F4F6F7',
  blue: '#0045AF',
  transparent: 'transparent',
  icon: '#6F695F',
  text: '#373234',
}

export const theme: DefaultTheme = {
  colors: {
    ...commonColors,
    background: '#F2EDE7',
    text: '#373234',
  },
  fonts: {
    default: "'Inter', sans-serif",
    title: "'TT Commons Pro', sans-serif",
  },
  transitionTimes: {
    short: '0.3s',
    medium: '0.7s',
    long: '1.3s',
  },
  timeouts: {
    short: 300,
  },
  breakpoints: {
    mobile: breakpoints.mobile,
    tablet: breakpoints.tablet,
    laptop: breakpoints.laptop,
    desktop: breakpoints.desktop,
    desktopLg: breakpoints.desktopLg,
  },
  widths: {
    base: '1920px',
  },
}

export const darkTheme: DefaultTheme = {
  colors: {
    ...commonColors,
    background: '#222938',
    text: '#FFF',
  },
  fonts: {
    default: "'Inter', sans-serif",
    title: "'TT Commons Pro', sans-serif",
  },
  transitionTimes: {
    short: '0.3s',
    medium: '0.7s',
    long: '1.3s',
  },
  timeouts: {
    short: 300,
  },
  breakpoints: {
    mobile: breakpoints.mobile,
    tablet: breakpoints.tablet,
    laptop: breakpoints.laptop,
    desktop: breakpoints.desktop,
    desktopLg: breakpoints.desktopLg,
  },
  widths: {
    base: '1920px',
  },
}
