import { defaultImport } from 'default-import'
import defaultStyled from 'styled-components'
import { color, space, typography, layout, compose } from 'styled-system'
import { lineClampMixin } from './mixins'
import { CaptionProps, HeadingProps, TextProps } from './types'

const styled = defaultImport(defaultStyled)

const textProps = compose(color, space, layout, typography)

// Heading
export const Heading = styled.h1<HeadingProps>`
  font-family: ${({ theme }) => theme.fonts.title};
  font-weight: 600;
  ${({ variant }) => {
    switch (variant) {
      case 'h0':
        return `
          font-size: 4.8rem;
          line-height: 1.167;
          letter-spacing: -0.010em;
        `
      case 'h1':
        return `
          font-size: 2.8rem;
          line-height: 1.357;
        `
      case 'h2':
        return `
          font-size: 2.2rem;
          line-height: 1.455;
        `
      case 'h3':
        return `
          font-size: 1.8rem;
          line-height: 1.556;
        `
      default:
        return `
          font-size: 2.8rem;
          line-height: 1.357;
        `
    }
  }}
  ${textProps};
  ${({ lineClamp }) => lineClamp && lineClampMixin(lineClamp)};
`

export const Text = styled.p<TextProps>`
  font-family: ${({ theme }) => theme.fonts.default};
  white-space: pre-line;
  ${({ variant }) => {
    switch (variant) {
      case 'textRegular':
        return `
          font-size: 1.5rem;
          line-height: 1.467;
          letter-spacing: -0.017em;
          font-weight: 400;
        `
      case 'textMedium':
        return `
          font-size: 1.5rem;
          line-height: 1.467;
          letter-spacing: -0.017em;
          font-weight: 500;
        `
      case 'smallTextRegular':
        return `
          font-size: 1.3rem;
          line-height: 1.385;
          letter-spacing: -0.008em;
          font-weight: 400;
        `
      case 'smallTextMedium':
        return `
          font-size: 1.3rem;
          line-height: 1.385;
          letter-spacing: -0.008em;
          font-weight: 500;
        `
      default:
        return `
          font-size: 1.5rem;
          line-height: 1.467;
          letter-spacing: -0.017em;
          font-weight: 400;
        `
    }
  }}
  ${textProps};
  ${({ lineClamp }) => lineClamp && lineClampMixin(lineClamp)};
`

export const Caption = styled.p<CaptionProps>`
  font-family: ${({ theme }) => theme.fonts.default};
  ${({ variant }) => {
    switch (variant) {
      case 'captionRegular':
        return `
          font-size: 1.2rem;
          line-height: 1.333;
          letter-spacing: 0.004em;
          font-weight: 400;
        `
      case 'captionMedium':
        return `
          font-size: 1.2rem;
          line-height: 1.333;
          letter-spacing: 0.004em;
          font-weight: 500;
        `
      default:
        return `
          font-size: 1.2rem;
          line-height: 1.333;
          letter-spacing: 0.004em;
          font-weight: 400;
        `
    }
  }}
  ${textProps};
`

// Helpers
export const HiddenLabel = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
`
