import { FloatProps } from '@headlessui-float/react'
import { WidthProps } from 'styled-system'

export enum PopperActionVariant {
  WARNING = 'warning',
  DEFAULT = 'default',
}

export interface PopperActionStylesProps {
  variant?: PopperActionVariant
}

export interface PopperAction extends PopperActionStylesProps {
  name: string
  onClick: VoidFunction
  icon?: JSX.Element
  variant?: PopperActionVariant
  disabled?: boolean
}

export interface PopperProps {
  actions: PopperAction[]
  hiddenLabel: string
  floatProps?: Partial<FloatProps>
}

export interface PopperListProps extends WidthProps {
  onClose?: VoidFunction
  actions: PopperAction[]
}

export interface PopperListContainerProps {
  onClose?: VoidFunction
}

export interface PopperButtonProps extends PopperActionStylesProps {
  icon?: JSX.Element
  name: string
  onClick: VoidFunction
}
