import { DefaultTheme } from 'styled-components'
import { StatusVariant } from '../components/avatar/types'

export const getStatusIconStyles = (status: StatusVariant, theme: DefaultTheme) => {
  switch (status) {
    case StatusVariant.BUSY:
      return { fill: theme.colors.yellow }
    case StatusVariant.ONLINE:
      return { fill: theme.colors.green }
    case StatusVariant.OFFLINE:
    default:
      return { fill: theme.colors.greyDark, transform: 'rotate(180)' }
  }
}
