import { defaultImport } from 'default-import'
import defaultStyled, { css, StyledConfig } from 'styled-components'
import {
  space,
  position,
  layout,
  flexbox,
  typography,
  border,
  borderRadius,
  compose,
  color,
} from 'styled-system'
import { theme } from '../../common/theme'
import type { ContainerStylesProps } from './types'

const styled = defaultImport(defaultStyled)
const styledSystemProps = ['display', 'height', 'overflow']

const composedContainerStyledProps = compose(
  color,
  space,
  position,
  layout,
  flexbox,
  border,
  borderRadius,
  typography
)

export const generalSettings: StyledConfig = {
  shouldForwardProp: (prop, defFn) => {
    return defFn(prop) && !styledSystemProps.includes(prop)
  },
}

// Basic Container with custom flexGap prop
// margin trick to avoid using gap property which is not working properly in some browsers
export const Container = styled('div').withConfig(generalSettings)<ContainerStylesProps>`
  ${(props) => {
    const flexboxStyles = css`
      margin: -${props.flexGap} 0 0 -${props.flexGap};
      & > * {
        margin: ${props.flexGap} 0 0 ${props.flexGap};
      }
    `

    return css`
      ${props.flexGap && flexboxStyles}
      ${composedContainerStyledProps(props)}
    `
  }}
`

// Rounded Containers
export const RoundedContainer = styled(Container).withConfig(generalSettings)<ContainerStylesProps>`
  background-color: ${theme.colors.white};
  border-radius: 2rem 2rem 0;
`

export const RoundedContainerReverse = styled(RoundedContainer)`
  border-radius: 2rem 2rem 2rem 0;
`

// ImgContainer is wrapping img element, img responds to the container without deformation
export const ImgContainer = styled(Container).withConfig(generalSettings)<ContainerStylesProps>`
  & img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`
