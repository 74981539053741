import styled, { useTheme } from 'styled-components'
import { ArchiveIcon, UserDeletedIcon } from '../icons'
import React from 'react'

import { Text } from '../typography'
import { Container } from '../container'

export interface DeletedAvatarProps {
  iconSize?: number
  flag?: string
}

export const DeletedAvatar: React.FC<DeletedAvatarProps> = ({ flag, iconSize }) => {
  const theme = useTheme()
  return (
    <>
      <UserDeletedIcon
        fill={theme.colors.greySuperDark}
        width={iconSize || 25}
        height={iconSize || 25}
      />
      {flag && (
        <Flag>
          <Container as="span" mr="1.2rem" height="2.4rem" width="2.4rem">
            <ArchiveIcon fill={theme.colors.white} />
          </Container>
          <Text fontSize="1.4rem" color={theme.colors.white} mb="-0.5rem">
            {flag}
          </Text>
        </Flag>
      )}
    </>
  )
}

const Flag = styled.span`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 4rem;
  padding: 0.6rem 1.2rem;
  background-color: ${({ theme }) => theme.colors.red};
`
