import React from 'react'

export const ChevronUpIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="m11.293 8.293-5 5a1 1 0 0 0 0 1.414 1 1 0 0 0 1.414 0L12 10.414l4.293 4.293a1 1 0 0 0 1.414 0 1 1 0 0 0 0-1.414l-5-5a1 1 0 0 0-1.414 0z" />
    </svg>
  )
}
