import { defaultImport } from 'default-import'
import React from 'react'
import defaultStyled from 'styled-components'
import { HelpTooltip } from '../../help-tooltip'
import { Caption } from '../../typography'
import { LabelBaseProps } from './types'

const styled = defaultImport(defaultStyled)

interface LabelWrapperProps {
  hasHelpIcon: boolean
}

interface InputLabelProps extends LabelBaseProps {
  labelText: string
  tooltipText?: string
  htmlFor?: string
}

export const InputLabel: React.FC<InputLabelProps> = ({
  labelText,
  tooltipText,
  htmlFor,
  ...rest
}) => {
  const hasHelpIcon = !!tooltipText

  return (
    <LabelWrapper hasHelpIcon={hasHelpIcon}>
      <Caption as="label" color="greyDark" hasHelpIcon={hasHelpIcon} htmlFor={htmlFor} {...rest}>
        {labelText}
      </Caption>
      {tooltipText && <HelpTooltip text={tooltipText} ml="0.4rem" />}
    </LabelWrapper>
  )
}

const LabelWrapper = styled.div<LabelWrapperProps>`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => (props.hasHelpIcon ? '0.6rem' : '0.8rem')};
`
