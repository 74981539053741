export const hasTransparentPixels = (img: HTMLImageElement): boolean => {
  try {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    if (!ctx) return false

    canvas.width = img.width
    canvas.height = img.height
    ctx.drawImage(img, 0, 0, img.width, img.height)

    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
    const { data } = imageData

    // Traverse every pixel in the image data
    for (let i = 3; i < data.length; i += 4) {
      const alpha = data[i]

      // Check if the alpha value indicates transparency
      if (alpha < 255) {
        return true
      }
    }

    // No transparent pixels found
    return false
  } catch (error) {
    console.error('Error in checking for transparent pixels:', error)
    return false
  }
}
