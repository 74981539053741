import { TooltipProps } from '@mui/material/Tooltip'
import React, { ReactNode } from 'react'
import { SpaceProps } from 'styled-system'
import { Text } from '../typography'
import { StyledTooltip } from './styled'

export interface CustomTooltipProps extends TooltipProps, SpaceProps {
  text: string
  disabled?: boolean
  fragment?: JSX.Element
}

export type PlacementType =
  | 'right'
  | 'left'
  | 'top'
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'right-end'
  | 'right-start'
  | 'top-end'
  | 'top-start'
  | undefined

export const Tooltip = (props: Partial<CustomTooltipProps>) => {
  const { children, disabled = false, text, fragment, ...restProps } = props

  if (disabled) {
    return <>{children}</>
  }

  return (
    <>
      {children && (
        <StyledTooltip
          arrow
          enterDelay={100}
          leaveDelay={50}
          title={
            fragment ? (
              fragment
            ) : (
              <Text color="white" variant="smallTextRegular">
                {text}
              </Text>
            )
          }
          {...restProps}
        >
          {children}
        </StyledTooltip>
      )}
    </>
  )
}
