import React, { ChangeEvent, FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { flexbox, layout, space } from 'styled-system'
import {
  CloseIcon,
  HiddenLabel,
  InputSizeVariant,
  InputVariant,
  SearchIcon,
  TextField,
  theme,
  useDebounce,
} from 'ui'
import { BasicSearchProps } from './types'

export const BasicSearch: FC<BasicSearchProps> = ({
  placeholder,
  id,
  hiddenLabel,
  name,
  searchString,
  setSearchString,
  size,
  delay,
  ...stylesProps
}) => {
  const [inputValue, setInputValue] = useState<string>('')
  const { t } = useTranslation('common')

  // possible to send request to the backend
  // access to latest state here
  const debouncedRequest = useDebounce(() => {
    setSearchString(inputValue.toLowerCase())
  }, delay)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setInputValue(value)
    debouncedRequest()
  }

  return (
    <SearchContainer {...stylesProps}>
      <TextField
        id={id}
        name={name}
        placeholder={placeholder}
        type="search"
        value={inputValue}
        onChange={handleChange}
        variant={InputVariant.SECONDARY}
        sizeVariant={InputSizeVariant.SM}
        hiddenLabel={hiddenLabel}
        onKeyDown={(e) => {
          if (e.code === 'Enter') {
            e.preventDefault()
          }
        }}
      />
      <InputPostfix>
        {inputValue ? (
          <ClearButton
            type="button"
            onClick={() => {
              setInputValue('')
              setSearchString('')
            }}
          >
            <CloseIcon width={18} height={18} />
            <HiddenLabel>{t('clearInput')}</HiddenLabel>
          </ClearButton>
        ) : (
          <SearchIcon fill={theme.colors.greyDark} />
        )}
      </InputPostfix>
    </SearchContainer>
  )
}

const SearchContainer = styled.form`
  position: relative;
  ${layout}
  ${space}
  ${flexbox}
`

const InputPostfix = styled.div`
  position: absolute;
  right: 1rem;
  top: 50%;
  height: 2.4rem;
  transform: translateY(-50%);
`

const ClearButton = styled.button`
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.grey};
`
