import React from 'react'

export const CheckmarkIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M18.947 6a1 1 0 0 0-.691.33L9.96 15.547l-4.256-4.254a1 1 0 0 0-1.412 0 1 1 0 0 0 0 1.414l5 5a1 1 0 0 0 1.45-.037l9-10a1 1 0 0 0-.073-1.414A1 1 0 0 0 18.947 6z" />
    </svg>
  )
}
