import React from 'react'

export const EyeMuteIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M17.878 9.339C16.424 8.53 14.348 8 12 8a1 1 0 1 1 0-2c2.623 0 5.046.588 6.85 1.59C20.62 8.573 22 10.091 22 12c0 1.254-.608 2.353-1.511 3.221a1 1 0 1 1-1.386-1.442c.628-.603.897-1.212.897-1.779 0-.853-.634-1.835-2.122-2.661Zm-12.77.691C4.33 10.692 4 11.375 4 12c0 .853.634 1.835 2.122 2.661C7.576 15.47 9.652 16 12 16c1.543 0 2.976-.23 4.194-.621a1 1 0 0 1 .612 1.904c-1.43.46-3.07.717-4.806.717-2.623 0-5.046-.588-6.85-1.59C3.38 15.427 2 13.909 2 12c0-1.39.744-2.585 1.813-3.493 1.068-.909 2.528-1.598 4.18-2.02l.496 1.938c-1.442.368-2.602.943-3.38 1.605Zm8.555 3.081A2 2 0 0 0 14 12h-2v-2a2 2 0 1 0 1.663 3.111Z" />
      <path d="M5 3a1 1 0 0 0-.707.293 1 1 0 0 0 0 1.414l16 16a1 1 0 0 0 1.414 0 1 1 0 0 0 0-1.414l-16-16A1 1 0 0 0 5 3Z" />
    </svg>
  )
}
