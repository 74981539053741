import React from 'react'

export const CopyIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M5.17574 5.17574C5.28826 5.06321 5.44087 5 5.6 5H12.8C12.9591 5 13.1117 5.06321 13.2243 5.17574C13.3368 5.28826 13.4 5.44087 13.4 5.6V6.4C13.4 6.95228 13.8477 7.4 14.4 7.4C14.9523 7.4 15.4 6.95228 15.4 6.4V5.6C15.4 4.91044 15.1261 4.24912 14.6385 3.76152C14.1509 3.27393 13.4896 3 12.8 3H5.6C4.91044 3 4.24912 3.27393 3.76152 3.76152C3.27393 4.24912 3 4.91044 3 5.6V12.8C3 13.4896 3.27393 14.1509 3.76152 14.6385C4.24912 15.1261 4.91044 15.4 5.6 15.4H6.4C6.95228 15.4 7.4 14.9523 7.4 14.4C7.4 13.8477 6.95228 13.4 6.4 13.4H5.6C5.44087 13.4 5.28826 13.3368 5.17574 13.2243C5.06321 13.1117 5 12.9591 5 12.8V5.6C5 5.44087 5.06321 5.28826 5.17574 5.17574ZM10.6 11.2C10.6 10.8686 10.8686 10.6 11.2 10.6H18.4C18.7314 10.6 19 10.8686 19 11.2V18.4C19 18.7314 18.7314 19 18.4 19H11.2C10.8686 19 10.6 18.7314 10.6 18.4V11.2ZM11.2 8.60001C9.76406 8.60001 8.6 9.76407 8.6 11.2V18.4C8.6 19.8359 9.76406 21 11.2 21H18.4C19.8359 21 21 19.8359 21 18.4V11.2C21 9.76407 19.8359 8.60001 18.4 8.60001H11.2Z" />
    </svg>
  )
}
