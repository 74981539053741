import React from 'react'

export const EyeIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M17.878 9.339C16.424 8.53 14.348 8 12 8c-2.348 0-4.424.53-5.878 1.339C4.634 10.165 4 11.147 4 12H2c0-1.909 1.38-3.427 3.15-4.41C6.954 6.588 9.377 6 12 6c2.623 0 5.046.588 6.85 1.59C20.62 8.573 22 10.091 22 12c0 1.909-1.38 3.427-3.15 4.41C17.046 17.412 14.622 18 12 18c-2.623 0-5.046-.588-6.85-1.59C3.38 15.427 2 13.909 2 12h2c0 .853.634 1.835 2.122 2.661C7.576 15.47 9.652 16 12 16c2.348 0 4.424-.53 5.878-1.339C19.366 13.835 20 12.853 20 12s-.634-1.835-2.122-2.661ZM12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
    </svg>
  )
}
