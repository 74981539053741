import { defaultImport } from 'default-import'
import React from 'react'
import defaultStyled, { css, DefaultTheme } from 'styled-components'
import { space } from 'styled-system'
import { generalSettings } from '../container'
import { DownloadIcon } from '../icons'
import { HiddenLabel } from '../typography'
import type {
  GeneralDownloadLinkProps,
  DownloadLinkParsedProperties,
  StyledDownloadLinkProps,
  DownloadLinkVariant,
} from './types'

const styled = defaultImport(defaultStyled)

function parseLinkStyles(
  theme: DefaultTheme,
  variant?: DownloadLinkVariant
): DownloadLinkParsedProperties {
  switch (variant) {
    case 'secondary':
      return {
        color: {
          normal: theme.colors.greyDark,
          hover: theme.colors.black,
          disabled: theme.colors.grey,
        },
      }
    case 'primary':
    default:
      return {
        color: {
          normal: theme.colors.primary,
          hover: theme.colors.primaryDark,
          disabled: theme.colors.primaryLight,
        },
      }
  }
}

export const DownloadLink: React.FC<GeneralDownloadLinkProps> = ({
  variant,
  href,
  disabled,
  hiddenLabel,
  ...spacing
}) => {
  return (
    <StyledLink variant={variant} href={href} disabled={disabled} download {...spacing}>
      <DownloadIcon />
      <HiddenLabel>{hiddenLabel}</HiddenLabel>
    </StyledLink>
  )
}

const StyledLink = styled.a.withConfig(generalSettings)<StyledDownloadLinkProps>`
  ${(props) => {
    const { color } = parseLinkStyles(props.theme, props.variant)

    return css`
      color: ${props.disabled ? color.disabled : color.normal};
      cursor: ${props.disabled ? 'default' : 'pointer'};
      pointer-events: ${props.disabled ? 'none' : 'default'};
      &:hover {
        color: ${!props.disabled && color.hover};
      }
    `
  }};
  display: inline-block;
  ${space}
`
