import CSS from 'csstype'
import { ButtonHTMLAttributes, ComponentType, Dispatch, SetStateAction } from 'react'
import { MaxWidthProps, PositionProps } from 'styled-system'
import { ModalType } from '../../contexts/modal-provider/types'

export enum ModalVariant {
  DEFAULT = 'default',
  FULL = 'full',
}

export enum ModalButtonVariant {
  DEFAULT = 'default',
  LIGHT = 'light',
}

export interface CustomPanelProps extends MaxWidthProps {
  variant?: ModalVariant
}

export type ExtractProps<T> = T extends ComponentType<infer P> ? P : T

export type ExtendedPanelProps<T> = T extends ComponentType<infer P> ? P : T & CustomPanelProps

export interface ModalProps extends Partial<ModalType> {
  children: any
  isOpen: boolean
  closeModal: () => void
  setIsOpen?: Dispatch<SetStateAction<boolean>>
}

export interface ModalButtonProps extends PositionProps, ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ModalButtonVariant
  children?: JSX.Element
}

export interface BackdropProps {
  backdropColor?: CSS.Property.Color
}
