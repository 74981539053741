import React from 'react'
import styled from 'styled-components'
import { space } from 'styled-system'
import { Container } from '../container'
import { Text } from '../typography'
import { DividerStylesProps, GeneralDividerProps } from './types'

export const Divider: React.FC<GeneralDividerProps> = ({ label, color, ...spacing }) => {
  if (!label) {
    return <PlainDivider color={color} {...spacing} />
  }

  return (
    <DividerContainer color={color} {...spacing}>
      <Text as="span" fontSize="1.4rem">
        {label?.toUpperCase()}
      </Text>
    </DividerContainer>
  )
}

export const DividerContainer = styled.p<DividerStylesProps>`
  display: block;
  text-align: center;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    width: calc(50% - 2rem);
    height: 0.1rem;
    background-color: ${(props) => props.color || props.theme.colors.grey};
    transform: translateY(-50%);
  }
  &::after {
    content: '';
    left: 0;
    position: absolute;
    top: 50%;
    width: calc(50% - 2rem);
    height: 0.1rem;
    background-color: ${(props) => props.color || props.theme.colors.grey};
    transform: translateY(-50%);
  }
  ${space}
`
export const PlainDivider = styled(Container)`
  width: 100%;
  height: 0.1rem;
  background-color: ${(props) => props.color || props.theme.colors.greyLight};
  transform: translateY(-50%);
`
