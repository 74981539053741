import React from 'react'

export const ResetIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M6 5.002a1 1 0 0 0-1 1v3.6a1 1 0 0 0 1 1h3.693a1 1 0 0 0 1-1 1 1 0 0 0-1-1H8.14c.493-.507 1.054-.947 1.728-1.22a5.3 5.3 0 0 1 3.432-.179 5.153 5.153 0 0 1 2.781 1.945c.669.935.987 2.059.908 3.19a4.925 4.925 0 0 1-1.345 3.043 5.203 5.203 0 0 1-3.03 1.562 5.29 5.29 0 0 1-3.375-.63 5.04 5.04 0 0 1-2.209-2.534 1 1 0 0 0-1.297-.566 1 1 0 0 0-.564 1.297 7.044 7.044 0 0 0 3.082 3.54 7.282 7.282 0 0 0 4.652.874 7.205 7.205 0 0 0 4.194-2.17 6.929 6.929 0 0 0 1.886-4.277 6.894 6.894 0 0 0-1.275-4.493 7.152 7.152 0 0 0-3.86-2.705 7.295 7.295 0 0 0-4.728.25A7.141 7.141 0 0 0 7 6.877v-.875a1 1 0 0 0-1-1z" />
    </svg>
  )
}
