import { ReactNode } from 'react'
import { FlexboxProps, LayoutProps, SpaceProps } from 'styled-system'

export interface Option {
  value: string
  label: string
  icon?: string
  desc?: string
  preFix?: ReactNode
}

export enum RadioGroupDirection {
  COLUMN = 'column',
  ROW = 'row',
}
export interface RadioGroupContainerProps {
  direction?: RadioGroupDirection
}

export interface RadioGroupProps
  extends RadioGroupContainerProps,
    SpaceProps,
    LayoutProps,
    FlexboxProps {
  name: string
  options: Option[]
  values: any
  onChange?: any
  border?: boolean
  reverse?: boolean
}
