import React from 'react'

export const MicrophoneIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25 10C25 7.23858 22.7614 5 20 5C17.2386 5 15 7.23858 15 10V18.3333C15 21.0948 17.2386 23.3333 20 23.3333C22.7614 23.3333 25 21.0948 25 18.3333V10Z"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.6663 18.3335C31.6663 19.8656 31.3646 21.3827 30.7783 22.7981C30.192 24.2136 29.3326 25.4997 28.2493 26.5831C27.1659 27.6664 25.8798 28.5258 24.4643 29.1121C23.0489 29.6984 21.5318 30.0002 19.9997 30.0002M19.9997 30.0002C18.4676 30.0002 16.9505 29.6984 15.535 29.1121C14.1196 28.5258 12.8334 27.6664 11.7501 26.5831C10.6667 25.4997 9.80738 24.2136 9.22108 22.7981C8.63477 21.3827 8.33301 19.8656 8.33301 18.3335M19.9997 30.0002V35.0002"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
