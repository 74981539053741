import { defaultImport } from 'default-import'
import { ReactElement } from 'react'
import defaultStyled, { css } from 'styled-components'
import { space } from 'styled-system'
import { generalSettings } from '../../container'
import { GenericInputStyleProps, InputSizeVariant, InputVariant } from './types'

const styled = defaultImport(defaultStyled)

function parseGenericInputVariant(variant?: InputVariant, warning?: boolean) {
  switch (variant) {
    case InputVariant.GHOST:
      return css`
        background-color: ${({ theme }) => theme.colors.transparent};
        border-color: ${({ theme }) => theme.colors.transparent};
      `
    case InputVariant.SECONDARY:
      return css`
        background-color: ${({ theme }) => theme.colors.greyLight};
        border-color: ${({ theme }) => theme.colors.transparent};
      `
    case InputVariant.PRIMARY:
    default:
      return css`
        background-color: ${({ theme }) => theme.colors.white};
        border: 1px solid ${({ theme }) => (warning ? theme.colors.red : theme.colors.grey)};
        &:focus {
          border-color: ${({ theme }) => (warning ? theme.colors.red : theme.colors.primary)};
        }
        &:disabled:focus {
          border-color: ${({ theme }) => theme.colors.grey};
        }
      `
  }
}

function parseGenericInputStyles(
  sizeVariant?: InputSizeVariant,
  helperText?: string | null,
  postFix?: ReactElement
) {
  const mainPadding = postFix ? '1.2rem 4.4rem 1.2rem 1.2rem' : '1.2rem'
  const helperPadding = '0.4rem 1.2rem 1.8rem' // when there is helper text we need to move main text up

  switch (sizeVariant) {
    case InputSizeVariant.SM:
      return css`
        height: 4rem;
        font-size: 1.3rem; // smallTextRegular
        line-height: 1.385;
        letter-spacing: -0.008em;
        font-weight: 400;
        padding: ${helperText ? helperPadding : mainPadding};
      `
    case InputSizeVariant.DEFAULT:
    default:
      return css`
        height: 4.8rem;
        font-size: 1.5rem; // textRegular
        line-height: 1.467;
        letter-spacing: -0.017em;
        font-weight: 400;
        padding: ${helperText ? helperPadding : mainPadding};
      `
  }
}

export const GenericInput = styled('input').withConfig(generalSettings)<GenericInputStyleProps>`
  border-radius: 0.7rem;
  width: 100%;
  min-width: 10rem;
  outline: 0;
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.default};
  transition: border-color ${({ theme }) => theme.transitionTimes.short},
    background-color ${({ theme }) => theme.transitionTimes.short};
  ${({ variant, warning }) => parseGenericInputVariant(variant, warning)}
  ${({ sizeVariant, helperText, postFix }) =>
    parseGenericInputStyles(sizeVariant, helperText, postFix)};
  &:-webkit-autofill {
    -webkit-text-fill-color: ${({ theme }) => theme.colors.black} !important;
  }
  &::placeholder {
    color: ${({ theme }) => theme.colors.greyDark};
  }
  &:disabled {
    cursor: default;
    color: ${({ theme }) => theme.colors.greyDark};
    background-color: ${({ theme }) => theme.colors.greyLighter};
  }
  ${space};
`
