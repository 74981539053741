import React, { FC } from 'react'
import Lottie from 'react-lottie'
import { Options } from 'react-lottie'
import { LottieAnimationProps } from './types'

const defaultOptions: Options = {
  loop: true,
  autoplay: true,
  animationData: undefined,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

export const LottieAnimation: FC<LottieAnimationProps> = ({
  size,
  width,
  height,
  lottieOptions,
  animationData,
  style,
}) => {
  const options = {
    ...defaultOptions,
    animationData,
    ...lottieOptions,
  }
  return (
    <Lottie
      options={options}
      isClickToPauseDisabled={true}
      height={size ? size : height}
      width={size ? size : width}
      style={style}
    />
  )
}
