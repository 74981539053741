import React from 'react'

export const StatusLgIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="m16.763 14.75-3.464-6a1.5 1.5 0 0 0-2.598 0l-3.464 6A1.5 1.5 0 0 0 8.536 17h6.928a1.5 1.5 0 0 0 1.3-2.25Z" />
    </svg>
  )
}
