import { Dialog } from '@headlessui/react'
import React, { FC } from 'react'
import styled from 'styled-components'
import { SpaceProps, layout, space } from 'styled-system'
import { CloseIcon, Container } from 'ui'
import { ModalButton } from './button'
import {
  BackdropProps,
  ExtendedPanelProps,
  ExtractProps,
  ModalButtonVariant,
  ModalProps,
  ModalVariant,
} from './types'

export const Modal: FC<ModalProps> = ({
  maxWidth = '44.8rem',
  children,
  isOpen,
  closeModal,
  closeOnClickOutside,
  variant = ModalVariant.DEFAULT,
  backdropColor,
  hasCloseButton,
}) => {
  const FullModal = () => (
    <FullScreenContainer>
      {hasCloseButton && (
        <ModalButton
          onClick={closeModal}
          position="absolute"
          top="3.2rem"
          right="3.2rem"
          aria-labelledby="Close"
          variant={ModalButtonVariant.LIGHT}
        >
          <CloseIcon />
        </ModalButton>
      )}
      {children}
    </FullScreenContainer>
  )

  const DefaultModal = () => (
    <FullScreenContainer p="2rem 3.2rem">
      <StyledPanel maxWidth={maxWidth}>
        <ModalButton
          onClick={closeModal}
          position="absolute"
          top="1.6rem"
          right="1.6rem"
          aria-labelledby="Close"
        >
          <CloseIcon />
        </ModalButton>
        {children}
      </StyledPanel>
    </FullScreenContainer>
  )

  return (
    <StyledDialog open={isOpen} onClose={closeOnClickOutside ? closeModal : () => {}}>
      <Backdrop aria-hidden="true" backdropColor={backdropColor} />
      <FullScreenContainer>
        {variant === ModalVariant.FULL ? <FullModal /> : <DefaultModal />}
      </FullScreenContainer>
    </StyledDialog>
  )
}

const StyledDialog = styled(Dialog)<ExtractProps<typeof Dialog>>`
  position: relative;
  z-index: 50;
`

const Backdrop = styled.div<BackdropProps>`
  position: fixed;
  inset: 0;
  background: ${({ backdropColor }) => backdropColor ?? 'rgba(55, 50, 52, 0.8)'};
`

const FullScreenContainer = styled.div<SpaceProps>`
  position: fixed;
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${space}
`

const StyledPanel = styled(Dialog.Panel)<ExtendedPanelProps<ExtractProps<typeof Dialog.Panel>>>`
  width: 100%;
  margin: 0 auto;
  position: relative;
  background: ${({ theme }) => theme.colors.white};
  padding: 3.6rem 2.4rem;
  border-radius: 1.6rem 1.6rem 1.6rem 0;
  ${layout}
`
