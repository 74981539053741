import { DefaultTheme } from 'styled-components'
import { breakpoints } from './media-queries'

export const theme: DefaultTheme = {
  colors: {
    white: '#FFF',
    black: '#373234',
    primary: '#003BA4',
    primaryDarker: '#003683',
    primaryDark: '#002861',
    primaryLight: '#99B1DB',
    primaryLighter: '#D6E0F0',
    red: '#D24821',
    redDark: '#A03050',
    redLight: '#F8DBD3',
    redLighter: '#FCEDE9',
    green: '#007443',
    greenLight: '#CCFFE9',
    greenLighter: '#F0FFF9',
    yellow: '#F6B439',
    yellowLighter: '#FFF9EE',
    background: '#F2EDE7',
    grey: '#D1CBC4',
    greyLighter: '#FCFAF6',
    greyLight: '#F6F3EE',
    greyDark: '#A9A49C',
    greySuperDark: '#6F695F',
    pinkLight: '#FFF7EF',
    beige: '#E9E2DA',
    beigeLight: '#F0E9E1',
    beigeDark: '#E8E1D9',
    smoke: '#F4F6F7',
    blue: '#0045AF',
    transparent: 'transparent',
    icon: '#6F695F',
  },
  fonts: {
    default: "'Inter', sans-serif",
    title: "'TT Commons Pro', sans-serif",
  },
  transitionTimes: {
    short: '0.3s',
    medium: '0.7s',
    long: '1.3s',
  },
  timeouts: {
    short: 300,
  },
  breakpoints: {
    mobile: breakpoints.mobile,
    tablet: breakpoints.tablet,
    laptop: breakpoints.laptop,
    desktop: breakpoints.desktop,
    desktopLg: breakpoints.desktopLg,
  },
  widths: {
    base: '1920px',
  },
}
