import { Float } from '@headlessui-float/react'
import { Popover } from '@headlessui/react'
import React, { FC } from 'react'
import styled from 'styled-components'
import { MoreIcon } from '../../icons/more'
import { PopperList } from '../popper-list'
import { PopperProps } from '../types'

export const Popper: FC<PopperProps> = ({ actions, hiddenLabel, floatProps }) => (
  <Popover style={{ width: '2.4rem', height: '2.4rem' }}>
    {({ open, close }) => (
      <Float show={open} flip {...floatProps}>
        <PopoverButton aria-label={hiddenLabel}>
          <MoreIcon />
        </PopoverButton>
        <Popover.Panel>
          <PopperList onClose={close} actions={actions} />
        </Popover.Panel>
      </Float>
    )}
  </Popover>
)

const PopoverButton = styled(Popover.Button)`
  width: 2.4rem;
  height: 2.4rem;
  color: ${({ theme }) => theme.colors.greySuperDark};
  transition: color ${({ theme }) => theme.transitionTimes.short};

  &:hover,
  &:active,
  &:focus {
    color: ${({ theme }) => theme.colors.primary};
    outline: none;
  }
`
