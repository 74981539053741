import React from 'react'

export const PauseIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.3333 31.6668C15.1667 31.6668 16.6667 30.1668 16.6667 28.3335V11.6668C16.6667 9.8335 15.1667 8.3335 13.3333 8.3335C11.5 8.3335 10 9.8335 10 11.6668V28.3335C10 30.1668 11.5 31.6668 13.3333 31.6668ZM23.3333 11.6668V28.3335C23.3333 30.1668 24.8333 31.6668 26.6667 31.6668C28.5 31.6668 30 30.1668 30 28.3335V11.6668C30 9.8335 28.5 8.3335 26.6667 8.3335C24.8333 8.3335 23.3333 9.8335 23.3333 11.6668Z"
        fill="currentColor"
      />
    </svg>
  )
}
