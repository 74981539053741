import React, { ReactElement } from 'react'
import { FlexboxProps, SpaceProps, TypographyProps, WidthProps } from 'styled-system'
import { CustomColorProps } from '../../../utils/types'

export interface CustomProps {
  warning?: boolean
}

export enum InputVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  GHOST = 'ghost',
}

export enum InputSizeVariant {
  DEFAULT = 'default',
  SM = 'sm',
}

export interface GenericInputProps {
  variant?: InputVariant
  sizeVariant?: InputSizeVariant
  helperText?: string | null
  postFix?: ReactElement
}

export type GenericInputStyleProps = GenericInputProps & CustomProps & SpaceProps & CustomColorProps

export type LabelBaseProps = SpaceProps &
  TypographyProps &
  CustomColorProps & {
    hasHelpIcon?: boolean
  }

export interface TextFieldProps extends SpaceProps, WidthProps {
  id: string
  name: string
  label?: string
  hiddenLabel?: string
  maxLength?: number
  placeholder?: string
  type?: React.HTMLInputTypeAttribute
  inputmode?: string
  pattern?: string
  disabled?: boolean
  helperText?: string | null
  warning?: boolean
  postFix?: ReactElement
  value?: string
  iconHiddenLabel?: string
  toastMessage?: string
  copyToClipboard?: boolean
  toggleVisibility?: boolean
  autoFocus?: boolean
  variant?: InputVariant
  sizeVariant?: InputSizeVariant
  description?: string
  tooltipText?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onFocus?: () => void
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
}

export interface ToastMessageProps {
  message: string
  isVisible: boolean
}

export interface StyledToastMessageProps {
  isVisible: boolean
}

export interface EyeButtonProps {
  isValueVisible?: boolean
}

export interface LimitTextFieldProps extends SpaceProps, WidthProps, FlexboxProps {
  id: string
  name: string
  value: string
  maxCharacters: number
  placeholder?: string
  hiddenLabel?: string
  label?: string
  helperText?: string | null
  autoFocus?: boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  variant?: InputVariant
  warning?: boolean
}
