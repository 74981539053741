import React, { createContext, useState, useCallback, useEffect, ReactNode } from 'react'
import { Modal, ModalVariant } from 'ui'
import { ModalContextProps, ModalType } from './types'

const initData: ModalContextProps = {
  closeModal: () => {},
  openModal: () => {},
  modal: undefined,
}
const ModalContext = createContext<ModalContextProps>(initData)
/**
 * USAGE of MODAL
 * 1. Import ModalContext in a place where is a modal trigger (button)
 *	import { useModal } from 'ui'
 *	const { openModal } = useModal()
 *
 * 2. In onClick function in modal trigger use 'openModal' function from ModalContext with 3 parameters in an object:
 *	onClick={() =>
 *		openModal({
 *			content: <Component/>,
					 - modal content from modules/modals
					 - mandatory
 *			closeOnClickOutside: boolean
					- modal is closing on click outside or pressing Esc
					- optional, default is false
 *			maxWidth: string
					- optional, default is "44.8rem" which appears the most
			variant: ModalVariant.DEFAULT || ModalVariant.FULL
					- optional, default is ModalVariant.DEFAULT
			backdropColor: CSS color property
					- optional. default is transparent grey
 *		})
 */

const ModalProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [modal, setModal] = useState<ModalType>()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const closeModal = useCallback(() => {
    setModal(undefined)
    setIsOpen(false)
  }, [setModal, setIsOpen])

  const openModal = useCallback(
    ({
      content,
      closeOnClickOutside = false,
      maxWidth = '44.8rem',
      variant,
      backdropColor,
      hasCloseButton = true,
    }: ModalType) => {
      setModal({ content, closeOnClickOutside, maxWidth, variant, backdropColor, hasCloseButton })

      if (!isOpen) {
        setIsOpen(true)
      }
    },
    [setModal, setIsOpen]
  )

  return (
    <ModalContext.Provider value={{ closeModal, openModal, modal }}>
      {children}
      <Modal
        isOpen={isOpen}
        closeModal={closeModal}
        closeOnClickOutside={modal?.closeOnClickOutside}
        maxWidth={modal?.maxWidth}
        variant={modal?.variant || ModalVariant.DEFAULT}
        backdropColor={modal?.backdropColor}
        hasCloseButton={modal?.hasCloseButton}
      >
        {modal?.content}
      </Modal>
    </ModalContext.Provider>
  )
}

export { ModalContext, ModalProvider }
