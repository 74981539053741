import React from 'react'

export const NativeChatsIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="31" viewBox="0 0 28 31" fill="none">
      <path
        d="M12.9448 6.18164H6.45814C4.74533 6.18164 3.10268 6.87775 1.89154 8.11684C0.680409 9.35593 0 11.0365 0 12.7888L0 21.3017C0 22.1694 0.167045 23.0286 0.491596 23.8302C0.816148 24.6318 1.29185 25.3602 1.89154 25.9737C2.49124 26.5872 3.20318 27.0739 3.98672 27.406C4.77025 27.738 5.61004 27.9089 6.45814 27.9089H19.4267V13.5252V13.2976V12.8112C19.4262 11.0528 18.7431 9.36651 17.5275 8.12328C16.312 6.88005 14.6636 6.18164 12.9448 6.18164Z"
        fill="url(#paint0_linear_5059_7816)"
      />
      <path
        d="M20.9879 6.18164H12.948C11.1586 6.18244 9.44281 6.90406 8.17779 8.18785C6.91278 9.47163 6.20215 11.2125 6.20215 13.0276V24.0453H20.9879C21.7054 24.0454 22.4159 23.9021 23.0789 23.6235C23.7418 23.3449 24.3441 22.9366 24.8513 22.4217C25.3586 21.9069 25.7608 21.2957 26.0351 20.6231C26.3093 19.9505 26.4502 19.2297 26.4497 18.5018V11.7221C26.4497 10.2527 25.8743 8.84344 24.85 7.8044C23.8257 6.76537 22.4364 6.18164 20.9879 6.18164Z"
        fill="url(#paint1_linear_5059_7816)"
      />
      <path
        d="M12.8131 6.18164C11.0595 6.18244 9.37797 6.90406 8.13827 8.18785C6.89856 9.47163 6.20215 11.2125 6.20215 13.0276V24.0453H19.4269V13.0276C19.4269 11.212 18.7301 9.47065 17.4898 8.18678C16.2494 6.90291 14.5672 6.18164 12.8131 6.18164Z"
        fill="url(#paint2_linear_5059_7816)"
      />
      <path
        d="M28.0002 27.3238C28.0003 26.9948 27.9317 26.6691 27.7984 26.3651C27.665 26.0611 27.4694 25.7849 27.2228 25.5522C26.9762 25.3196 26.6834 25.135 26.3612 25.0091C26.0389 24.8832 25.6936 24.8184 25.3448 24.8184H8.85558C8.15184 24.8189 7.47708 25.0828 6.97955 25.5522C6.48202 26.0217 6.20241 26.6582 6.20215 27.3219V31.0002H24.0582H25.3468C26.05 30.9997 26.7243 30.7361 27.2217 30.2673C27.7192 29.7985 27.9991 29.1627 28.0002 28.4995V27.3238Z"
        fill="url(#paint3_linear_5059_7816)"
      />
      <path
        d="M24.124 1.90722V2.72914C24.124 3.23497 23.8832 3.72008 23.4544 4.07775C23.0257 4.43542 22.4442 4.63636 21.8378 4.63636L6.2022 4.63636V1.90722C6.2022 1.40139 6.44307 0.916286 6.87181 0.558612C7.30056 0.200939 7.88207 0 8.48841 0L21.8378 0C22.4442 0 23.0257 0.200939 23.4544 0.558612C23.8832 0.916286 24.124 1.40139 24.124 1.90722Z"
        fill="url(#paint4_linear_5059_7816)"
      />
      <path
        d="M18.2025 27.9093H19.4269V24.8184H8.76929C8.08843 24.8189 7.43562 25.0836 6.95427 25.5543C6.47292 26.025 6.20241 26.6633 6.20215 27.3288V27.9093H18.2025Z"
        fill="#004E35"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5059_7816"
          x1="9.71383"
          y1="20.7531"
          x2="9.71383"
          y2="42.5611"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0058E5" />
          <stop offset="1" stopColor="#003BA4" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5059_7816"
          x1="16.3239"
          y1="5.89543"
          x2="16.3239"
          y2="24.5635"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.01" stopColor="#FACA9D" />
          <stop offset="0.99" stopColor="#F4A061" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5059_7816"
          x1="12.8131"
          y1="6.06716"
          x2="12.8131"
          y2="24.0242"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#003BA4" />
          <stop offset="1" stopColor="#002861" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_5059_7816"
          x1="17.0997"
          y1="27.4052"
          x2="17.0997"
          y2="37.1167"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#007443" />
          <stop offset="1" stopColor="#013413" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_5059_7816"
          x1="15.1616"
          y1="-0.0342815"
          x2="15.1616"
          y2="6.62385"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E59E38" />
          <stop offset="1" stopColor="#BF7408" />
        </linearGradient>
      </defs>
    </svg>
  )
}
