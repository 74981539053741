import React, { FC } from 'react'
import styled, { css } from 'styled-components'
import { position } from 'styled-system'
import { ModalButtonProps, ModalButtonVariant } from './types'

export const ModalButton: FC<ModalButtonProps> = ({ variant, children, ...props }) => (
  <ModalButtonContainer type="button" variant={variant} {...props}>
    {children}
  </ModalButtonContainer>
)

const getModalButtonStyles = (variant: ModalButtonVariant) => {
  switch (variant) {
    case ModalButtonVariant.LIGHT:
      return css`
        color: ${({ theme }) => theme.colors.grey};
        &:hover,
        &:focus-visible {
          color: ${({ theme }) => theme.colors.white};
        }
      `
    default:
      return css`
        color: ${({ theme }) => theme.colors.greySuperDark};
        &:hover,
        &:focus-visible {
          color: ${({ theme }) => theme.colors.primary};
        }
      `
  }
}

const ModalButtonContainer = styled.button<ModalButtonProps>`
  outline: none;
  transition: color ${({ theme }) => theme.transitionTimes.short};
  ${({ variant }) => getModalButtonStyles(variant || ModalButtonVariant.DEFAULT)}
  ${position}
`
