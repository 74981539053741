import React from 'react'

export const ErrorIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M12 19a7 7 0 1 0 0-14 7 7 0 0 0 0 14Zm9-7a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-4.375a1 1 0 0 1 1 1V12a1 1 0 1 1-2 0V8.625a1 1 0 0 1 1-1ZM12 16a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
        clipRule="evenodd"
      />
    </svg>
  )
}
