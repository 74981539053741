import React from 'react'

export const TranslationIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12.713 15.62a.846.846 0 0 0-.192-.893l-1.742-1.751.025-.026a14.97 14.97 0 0 0 3.093-5.55h1.617c.45 0 .826-.383.826-.841V6.54a.841.841 0 0 0-.826-.841h-5.01v-.85A.844.844 0 0 0 9.67 4a.844.844 0 0 0-.834.85v.85h-5.01A.841.841 0 0 0 3 6.541c0 .468.375.842.825.842h8.488a13.37 13.37 0 0 1-2.643 4.564A13.158 13.158 0 0 1 7.952 9.5a.746.746 0 0 0-.27-.292.727.727 0 0 0-.38-.108c-.575 0-.942.638-.659 1.148.526.96 1.168 1.878 1.918 2.728L4.918 16.64a.842.842 0 0 0-.25.603.856.856 0 0 0 .25.604.829.829 0 0 0 1.183 0L9.67 14.2l1.684 1.717a.821.821 0 0 0 1.359-.297Zm4.043-4.82c-.5 0-.95.314-1.125.799l-3.06 8.33A.786.786 0 0 0 13.296 21a.784.784 0 0 0 .734-.518l.742-2.032h3.96l.75 2.032a.796.796 0 0 0 .734.518.794.794 0 0 0 .734-1.071l-3.06-8.33a1.217 1.217 0 0 0-1.134-.799Zm-1.35 5.95 1.35-3.68 1.351 3.68h-2.701Z" />
    </svg>
  )
}
