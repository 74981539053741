import React from 'react'

export const ShareIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.0443 8.45596C11.2252 7.6372 10.1144 7.17725 8.9563 7.17725C7.79815 7.17725 6.68741 7.6372 5.8683 8.45596L2.7793 11.544C1.96018 12.3631 1.5 13.474 1.5 14.6325C1.5 15.7909 1.96018 16.9018 2.7793 17.721C3.59842 18.5401 4.70939 19.0003 5.8678 19.0003C7.02621 19.0003 8.13718 18.5401 8.9563 17.721L10.5003 16.177"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.95605 11.5438C9.77516 12.3626 10.8859 12.8225 12.0441 12.8225C13.2022 12.8225 14.3129 12.3626 15.1321 11.5438L18.2211 8.45581C19.0402 7.63669 19.5004 6.52572 19.5004 5.36731C19.5004 4.2089 19.0402 3.09793 18.2211 2.27881C17.4019 1.45969 16.291 0.999512 15.1326 0.999512C13.9741 0.999512 12.8632 1.45969 12.0441 2.27881L10.5001 3.82281"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
