import Image from 'next/image'
import { FC } from 'react'
import styled from 'styled-components'
import { Button } from '../../../components/button'
import { Container } from '../../../components/container'
import { Heading } from '../../../components/typography'
import { Text } from '../../../components/typography'
import { useModal } from '../../../hooks/use-modal'
import { CancelationModalProps } from './types'

const CANCEL_SUBSCRIPTION_MODAL_IMG = '/images/cancel-subscription-modal.svg'

export const CancelSubscriptionModal: FC<CancelationModalProps> = ({
  onSubmit,
  title,
  cancelLabel,
  submitLabel,
  description1,
  description2,
  isDisabled,
  ...stylesProps
}) => {
  const { closeModal } = useModal()

  return (
    <FullScreenContainer {...stylesProps}>
      <Image src={CANCEL_SUBSCRIPTION_MODAL_IMG} width={906} height={288} alt={title} />
      <Heading as="p" variant="h0" mt="2rem">
        {title}
      </Heading>
      <Text textAlign="center" maxWidth={500} mt="1rem">
        {description1}
      </Text>
      <Text textAlign="center" mt="3rem">
        {description2}
      </Text>
      <ButtonsContainer>
        <Button
          type="submit"
          width="100%"
          disabled={isDisabled}
          onClick={onSubmit}
          variant="warning"
          style={{ backgroundColor: 'white' }}
        >
          {submitLabel}
        </Button>
        <Button variant="primary" width="100%" onClick={closeModal}>
          {cancelLabel}
        </Button>
      </ButtonsContainer>
    </FullScreenContainer>
  )
}

const FullScreenContainer = styled(Container)`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.background};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
  margin-top: 4rem;
`
