import React from 'react'
import styled, { css } from 'styled-components'
import { Container } from '../container'
import { RadioButton } from '../radio-button'
import { RadioGroupContainerProps, RadioGroupDirection, RadioGroupProps } from './types'

export const RadioGroup = (props: RadioGroupProps) => {
  const { name, options, reverse, border, onChange, values, direction, ...spacing } = props

  return (
    <RadioGroupContainer direction={direction} {...spacing}>
      {options.map(({ value, label, icon, desc, preFix }) => (
        <RadioGroupItem key={value}>
          <RadioButton
            name={name}
            value={value}
            label={icon ? `${label} ${icon}` : label}
            desc={desc}
            reverse={reverse}
            border={border}
            checked={values[name] === value}
            onChange={onChange}
            preFix={preFix}
          />
        </RadioGroupItem>
      ))}
    </RadioGroupContainer>
  )
}

const RadioGroupItem = styled.li`
  list-style: none;
`

const RadioGroupContainer = styled(Container)<RadioGroupContainerProps>`
  display: flex;
  ${({ direction }) => {
    const columnStyles = css`
      flex-direction: column;
      ${RadioGroupItem} {
        padding: 1.2rem 0;
      }
      ${RadioGroupItem}:not(:last-of-type) {
        border-bottom: 1px solid ${({ theme }) => theme.colors.greyLight};
      }
    `
    const rowStyles = css`
      flex-direction: row;
      ${RadioGroupItem} {
        flex: 1 1 auto;
      }
      ${RadioGroupItem}:not(:last-of-type) {
        margin-right: 1.2rem;
      }
    `
    return direction === RadioGroupDirection.COLUMN ? columnStyles : rowStyles
  }}
`
