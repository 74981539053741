import React from 'react'

export const StatusIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 12"
      width="12"
      height="12"
      fill="currentColor"
      {...props}
    >
      <path d="M7.743 2.56c-.764-1.36-2.722-1.36-3.486 0l-2.79 4.96c-.75 1.333.213 2.98 1.743 2.98h5.58c1.53 0 2.493-1.647 1.743-2.98z" />
      <path
        d="M6 .572c-1.027 0-2.054.499-2.615 1.496L.595 7.03C-.505 8.99.962 11.5 3.21 11.5h5.582c2.248 0 3.715-2.512 2.613-4.47L8.615 2.067C8.055 1.071 7.027.572 6 .572Zm0 1.934c.334 0 .668.18.871.543l2.791 4.96c.398.708-.06 1.491-.871 1.491H3.209c-.812 0-1.27-.783-.871-1.49l2.79-4.961c.204-.362.538-.543.872-.543Z"
        fill="#fff"
      />
    </svg>
  )
}
