import React, { FC } from 'react'
import styled from 'styled-components'
import { space } from 'styled-system'
import { HelpIcon, HelpTooltipProps, TooltipIconSize, Tooltip } from 'ui'

const sizeToRems: { [key in TooltipIconSize]: string } = {
  sm: '1.6rem',
  md: '2.2rem',
  lg: '2.4rem',
}

function mapSizeToRems(size?: TooltipIconSize) {
  return sizeToRems[size || 'lg']
}
export const HelpTooltip: FC<Partial<HelpTooltipProps>> = ({ size, icon, ...props }) => (
  <Tooltip style={{ cursor: 'pointer' }} placement="top" {...props}>
    <IconContainer size={size}>{icon ? icon : <HelpIcon />}</IconContainer>
  </Tooltip>
)

const IconContainer = styled.span<{ size?: TooltipIconSize }>`
  color: ${({ theme }) => theme.colors.greyDark};
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => mapSizeToRems(size)};
  height: ${({ size }) => mapSizeToRems(size)};
  & svg {
    width: inherit;
    height: inherit;
  }
  ${space}
`
