import React from 'react'

export const ChevronDownIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7 9a1 1 0 0 0-.707.293 1 1 0 0 0 0 1.414l5 5a1 1 0 0 0 1.414 0l5-5a1 1 0 0 0 0-1.414 1 1 0 0 0-1.414 0L12 13.586 7.707 9.293A1 1 0 0 0 7 9Z" />
    </svg>
  )
}
